import { Row } from 'antd'
import styled from 'styled-components'

export const HeaderBlock = styled(Row)`
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
    .tool {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: -55px;
      margin-left: 60px;
    }
    .images {
      width: 120px !important;
      height: 55px !important;
      padding-bottom: 20px;
    }
  }

  @media only screen and (min-width: 701px) and (max-width: 840px) {
    flex-direction: column;
    align-items: flex-start;
    .tool {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: -25px;
      margin-left: 40px;
    }
  }

  @media only screen and (min-width: 841px) and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
    .tool {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: -40px;
      margin-left: 40px;
    }
  }
`

export const ItemBlock = styled.span`
  margin-left: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: white; /* Màu chữ của các ItemBlock */
  cursor: pointer;

  &:hover {
    color: #1890ff; /* Màu chữ khi hover */
  }
`

export const FooterBlock = styled(Row)`
  color: white;
  background-color: var(--primary-color);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    :hover {
      cursor: pointer;
    }
    p {
      margin: 0.3rem 0;
    }
    .circle-item {
      aspect-ratio: 1 / 1;
      background-color: #febd69;
      border-radius: 50%;
      padding: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .main-footer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-active {
    color: var(--second-color);
  }
  @media only screen and (max-width: 576px) {
    font-size: 12px;
  }
  .menu-active[id='order'] {
    color: var(--primary-color);
  }
`
