import {
  FileDoneOutlined,
  ShoppingCartOutlined,
  UsergroupDeleteOutlined,
  AlipayOutlined,
} from '@ant-design/icons'
import { Button, Col, Divider, Image, Layout, message, Row, Select } from 'antd'
import R from 'assets'
import i18n from 'assets/i18n/translation'
import { ADMIN_ROUTER_PATH, SESSION_KEY } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineLock, AiOutlineLogout } from 'react-icons/ai'
import { BiLocationPlus, BiSupport } from 'react-icons/bi'
import {
  BsCardChecklist,
  BsFillDiagram3Fill,
  BsLock,
  BsReply,
} from 'react-icons/bs'
import { FaAddressCard, FaMoneyBillAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'redux/store/store'
import Swal from 'sweetalert2'
import { CHAT_LINK } from 'utils/constants'
import { formatSensitiveText } from 'utils/funcHelper'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'

const { Content } = Layout;
const { Option } = Select;

const HeadTitle = ({ title }: { title: string }) => {
  return (
    <div
      style={{
        backgroundColor: 'lightgray',
        fontWeight: 'bold',
        padding: 10,
        marginTop: 10,
        fontSize: 18,
      }}
    >
      {title}
    </div>
  )
}

const OptionSelect = ({
  icon,
  title,
  to,
}: {
  icon: unknown
  title: string
  to: string
}) => {
  return (
    <div
      style={{
        padding: 5,
        paddingLeft: 12,
        marginTop: 5,
        fontSize: 14,
      }}
    >
      <Link
        to={to}
        style={{ color: 'black', alignItems: 'center', display: 'flex' }}
      >
        {icon}
        <div style={{ width: 5 }}></div>
        {title}
      </Link>
    </div>
  )
}

const ProfilePage: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    dispatch(getUserInfoAction())
  }, [])

  const handleLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
  };

  return (
    <Content
      style={{
        padding: '0 10px',
        margin: '10px 0',
      }}
    >
      <Row justify="center">
        <Col span="12">
          <Row align="middle">
            <Col>
              <Image
                style={{ borderRadius: 5 }}
                width={70}
                height={70}
                src={R.images.avt_placeholder}
              />
            </Col>
            <Col style={{ marginLeft: 10 }}>
              <Row style={{ height: '100%' }} align="middle">
                <Col>
                  <Row>
                    <b style={{ fontSize: 20 }}>{userInfo?.name}</b>
                  </Row>
                  <Row>
                    <b style={{ fontSize: 16 }}>
                      {t('phone')}: {formatSensitiveText(userInfo?.phone, 4)}
                    </b>
                  </Row>
                  <Row>
                    <b style={{ fontSize: 16 }}>
                      {t('Surplus')}:{' '}
                      <b style={{ color: 'green' }}>{`$${formatPrice(userInfo?.balance) || 0
                        }`}</b>
                    </b>
                  </Row>
                  <Row>
                    <b style={{ fontSize: 14, color: 'grey' }}>
                      {t('code_invite')}:{' '}
                      {userInfo?.reference_code || userInfo?.phone}
                    </b>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span="12">
          <Row
            justify="end"
            align="middle"
            style={{ height: '100%', padding: 10 }}
          >
            <Col>
              <Button
                style={{ fontWeight: '700' }}
                onClick={() => {
                  // history.push(ADMIN_ROUTER_PATH.SUPPORT)
                  window.open(CHAT_LINK)
                }}
              >
                {t('recharge')}
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  dispatch(getUserInfoAction())

                  if (userInfo?.balance == 0) {
                    Swal.fire({
                      title: t('notification'),
                      text: t('warning_money'),
                      icon: 'error',
                      showCancelButton: true,
                    }).then((result: any) => {
                      if (result.isConfirmed) {
                        history.push(ADMIN_ROUTER_PATH.RECHARGE_MONEY)
                      }
                    })
                  } else {
                    if (userInfo.bank) {
                      history.push(ADMIN_ROUTER_PATH.WITHDRAW_MONEY)
                    } else {
                      message.error(t('warning_bank_card'))
                      history.push(ADMIN_ROUTER_PATH.BANK_CARD)
                    }
                  }
                }}
                style={{ fontWeight: '700' }}
              >
                {t('withdraw_money')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <HeadTitle title={t('user_info')} />
      {/* <OptionSelect
        to={ADMIN_ROUTER_PATH.DELIVERY_ADDRESS}
        title="Thông tin cá nhân"
        icon={<BiLocationPlus />}
      /> */}
      <OptionSelect
        to={ADMIN_ROUTER_PATH.BANK_CARD}
        title={t('bank_card')}
        icon={<BsCardChecklist />}
      />
      <OptionSelect
        to={ADMIN_ROUTER_PATH.MEMBER_RANK}
        title={t('membership_level')}
        icon={<BsFillDiagram3Fill />}
      />
      <OptionSelect
        to={ADMIN_ROUTER_PATH.DELIVERY_ADDRESS}
        title={t('address_reciver')}
        icon={<FaAddressCard />}
      />
      <HeadTitle title={t('order_history')} />
      <OptionSelect
        to={ADMIN_ROUTER_PATH.HISTORY}
        title={t('history_order')}
        icon={<FileDoneOutlined />}
      />
      <OptionSelect
        to={ADMIN_ROUTER_PATH.ORDER}
        title={t('reciver_order')}
        icon={<ShoppingCartOutlined />}
      />
      <OptionSelect
        to={`${ADMIN_ROUTER_PATH.MY_GROUP}?tabs=level_1`}
        title={t('my_group')}
        icon={<BsFillDiagram3Fill />}
      />
      <HeadTitle title={t('account')} />
      <OptionSelect
        to={ADMIN_ROUTER_PATH.HISTORY_TRANSACTION}
        title={t('history_transaction')}
        icon={<FaMoneyBillAlt />}
      />
      <OptionSelect
        to={ADMIN_ROUTER_PATH.CHANGE_PASS}
        title={t('password_account')}
        icon={<AiOutlineLock />}
      />
      <OptionSelect
        to={ADMIN_ROUTER_PATH.DEPOSIT_PASS}
        title={t('capital_password')}
        icon={<BsLock />}
      />
      <OptionSelect
        to={ADMIN_ROUTER_PATH.SUPPORT}
        title={t('feedback')}
        icon={<BsReply />}
      />

      <div style={{ height: 30, paddingLeft: 15, marginTop: 10 }}>
        <AlipayOutlined />{' '}
        <Select
          value={i18n.language}
          onChange={handleLanguageChange}
          style={{ marginLeft: 10, border: 'none', boxShadow: 'none' }}
        >
          <Option value="vi">Tiếng Việt</Option>
          <Option value="cn">Chinese</Option>
          <Option value="en">English</Option>
          <Option value="in">Indonesian</Option>
          <Option value="kr">Korean</Option>
          <Option value="jp">Japanese</Option>
        </Select>
      </div>
      <Divider style={{ margin: 5 }} />
      <div
        style={{
          padding: 5,
          paddingLeft: 12,
          marginTop: 5,
          marginBottom: 80,
        }}
        onClick={() => {
          const refWindow: any = window
          if (refWindow.$chatwoot) refWindow.$chatwoot.reset()
          Cookie.remove(SESSION_KEY.SESSION)
          message.success('Đăng xuất')
          history.replace('/')
        }}
      >
        <a style={{ color: 'black', fontSize: 18 }}>
          <AiOutlineLogout /> <b>{t('logout')}</b>
        </a>
      </div>
    </Content>
  )
}
export default ProfilePage
function getUserInfor(): any {
  throw new Error('Function not implemented.')
}
