import { Col, Dropdown, Menu, Select } from 'antd';
import R from 'assets';
import React from 'react';
import * as Style from './styled';
import { MdSupportAgent } from 'react-icons/md';
import { IoNotificationsOutline } from 'react-icons/io5';
import history from 'utils/history';
import { ADMIN_ROUTER_PATH } from 'common/config';
import { useTranslation } from 'react-i18next';
import i18n from 'assets/i18n/translation';

const { Option } = Select;

const HeaderContainer: React.FC = () => {
  const { t } = useTranslation();

  const handleLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
  };

  const menu = (
    <Menu style={{ width: 400, minHeight: 150 }}>
      <Menu.Item key={0}>
        <div style={{ background: '#ededed', padding: 7, borderRadius: 5 }}>
          <img src={R.images.notify} alt="Notification" style={{ width: 50, height: 50, marginRight: 10 }} />
          <div style={{ fontWeight: 600, fontSize: 16 }}>{t('notification')}</div>
          <div>{t('welcome')}</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Style.HeaderBlock justify="space-between">
      {/* Logo */}
      <Col xs={24} md={6}>
        <img
          alt="logo"
          className="images"
          src={R.images.logo_web}
          style={{
            width: '100%', // Logo sẽ chiếm toàn bộ phần tử Col
            maxWidth: 150, // Giới hạn kích thước tối đa của logo
            height: 'auto', // Giữ tỷ lệ hình ảnh
            display: 'block', // Block level để không bị căn giữa
            marginLeft: 'auto', // Canh chỉnh logo về bên trái
            marginRight: 'auto', // Canh chỉnh logo về bên phải
          }}
        />
      </Col>
      {/* Các phần tử ngôn ngữ, thông báo, hỗ trợ */}
      <Col xs={24} md={18} className="tool" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Select
          value={i18n.language}
          onChange={handleLanguageChange}
          style={{ marginLeft: 10, width: 120 }}
        >
          <Option value="vi">Tiếng Việt</Option>
          <Option value="cn">Chinese</Option>
          <Option value="en">English</Option>
          <Option value="in">Indonesian</Option>
          <Option value="kr">Korean</Option>
          <Option value="jp">Japanese</Option>
        </Select>
        {/* <Dropdown overlay={menu} trigger={['click']}>
          <Style.ItemBlock>
            <IoNotificationsOutline style={{ fontSize: '20px' }} />
            {t('notification')}
          </Style.ItemBlock>
        </Dropdown>
        <Style.ItemBlock onClick={() => history.push(ADMIN_ROUTER_PATH.SUPPORT)}>
          <MdSupportAgent style={{ fontSize: '20px' }} />
          {t('support1')}
        </Style.ItemBlock> */}
      </Col>
    </Style.HeaderBlock>
  );
};

export { HeaderContainer };
