import { Row } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { AiOutlineRight } from 'react-icons/ai'
import history from 'utils/history'
import * as Styled from '../styled'
import { useTranslation } from 'react-i18next'

const CatalogAboutUs: React.FC = () => {
  const { t } = useTranslation()
  const CATALOG_LIST = [
    {
      name: t('about_us'),
      link: 'https://plus.wish.com/gmkt.inc/Company/ActNotification.aspx',
      isExternal: true,
    },
    {
      name: t('describe'),
      link: 'https://merchantfaq.wish.com/hc/vi/articles/205211447-Ch%C3%A0o-m%E1%BB%ABng-%C4%91%E1%BA%BFn-v%E1%BB%9Bi-Wish',
      isExternal: true,
    },
    {
      name: t('finance'),
      link: 'https://merchantfaq.wish.com/hc/vi/articles/204531558-C%C3%A1ch-Wish-t%C3%ADnh-ph%C3%AD-hoa-h%E1%BB%93ng-t%E1%BB%AB-ng%C6%B0%E1%BB%9Di-b%C3%A1n',
      isExternal: true,
    },
    {
      name: t('corporate_culture'),
      link: 'https://plus.wish.com/gmkt.inc/Company/AboutCompany.aspx',
      isExternal: true,
    },
    {
      name: t('condition'),
      link: 'https://plus.wish.com/gmkt.inc/Company/UserAgreement.aspx',
      isExternal: true,
    },
    {
      name: t('income_form'),
      link: 'https://merchantfaq.wish.com/hc/vi/articles/4879501024795-Th%C3%B4ng-tin-thi%E1%BA%BFt-k%E1%BA%BF-l%E1%BA%A1i-%E1%BB%A9ng-d%E1%BB%A5ng-Wish-n%C4%83m-2022',
      isExternal: true,
    },
    {
      name: t('group_division'),
      link: 'https://merchantfaq.wish.com/hc/en-us/articles/4409934726683-Wish-Standards-Overview#h_01G23MPW7DFH29YH4VS4SCYM2V',
      isExternal: true,
    },
    {
      name: t('personal_policy'),
      link: 'https://merchantfaq.wish.com/hc/vi/articles/360048831574-X%C3%A1c-Minh-C%E1%BB%ADa-H%C3%A0ng',
      isExternal: true,
    },
  ]

  const handleItemClick = (link: string, isExternal: boolean) => {
    if (isExternal) {
      window.open(link, '_blank')
    } else {
      history.push(link)
    }
  }

  return (
    <Row
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      style={{ marginTop: '1rem' }}
    >
      {CATALOG_LIST.map((item: any) => (
        <Styled.ItemCatalog
          key={item.name}
          xs={12}
          md={8}
          xl={6}
          onClick={() => handleItemClick(item.link, item.isExternal)}
        >
          <div className="catalog-block">
            <div className="name-catalog">{item.name}</div>
            <div className="icon-catalog">
              <AiOutlineRight style={{ fontSize: '20px' }} />
            </div>
          </div>
        </Styled.ItemCatalog>
      ))}
    </Row>
  )
}
export default CatalogAboutUs
