import { LockOutlined, UserOutlined, AlipayOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Select } from 'antd'
// import LoadingProgress from "common/components/LoadingProgress";
import { SESSION_KEY } from 'common/config'
// import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import { useState } from 'react'
import { CHAT_LINK, REG_PHONE } from 'utils/constants'
import history from 'utils/history'
import R from 'assets'
import { requestLogin } from './AuthApi'
import './authStyle.css'
import { useDispatch } from 'react-redux'
import { getUserInfoAction } from './AuthSlice'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import i18n from 'assets/i18n/translation'



const handleSupportClick = () => {
  // Navigate to support center within the app
  window.open(CHAT_LINK, '_blank')

  // Or, open the support center in a new tab
  // window.open('URL_TO_SUPPORT_CENTER', '_blank', 'noopener,noreferrer');
};

const handleLanguageChange = (value: string) => {
  i18n.changeLanguage(value);
  localStorage.setItem('language', value);
};

function Login(props: any) {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const resLogin = await requestLogin({
        identifier: values.phone,
        password: values.password,
      });
      Cookie.set(SESSION_KEY.SESSION, resLogin.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      });
      dispatch(getUserInfoAction());
      message.success(t('login_success'));
      setTimeout(() => {
        history.replace('/');
      }, 1000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSupportClick = () => {
    window.open(CHAT_LINK, '_blank');
  };

  return (
    <div className="login">
      <img alt="" src={R.images.bg} className="login-bg" />
      <div className="container-login">
        <img className="login-logo" width={150} src={R.images.logo_web} alt="" />
        <Form
          form={form}
          layout={'vertical'}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
            phone: null,
            password: null,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="phone"
            label={t('phone')}
            rules={[
              { required: true, message: t('not_empty') },
              // {
              //   pattern: REG_PHONE,
              //   message: 'Số điện thoại không hợp lệ',
              // },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('phone')}
            />
          </Form.Item>
          <Form.Item
            label={t('password')}
            name="password"
            rules={[
              { required: true, message: t('not_empty') },
              { min: 6, message: t('min_password_length') },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('password')}
            />
          </Form.Item>

          <Form.Item
            style={{
              textAlign: 'center',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '100%', height: 40, fontWeight: 700 }}
            >
              {t('login')}
            </Button>
          </Form.Item>
        </Form>
        <p className="login-text__btm">
          {t('not_account')}? <a onClick={() => history.push('/register')}>{t('register')}</a>
        </p>
        <p
          className="login-text__btm"
          style={{ fontSize: 14, color: 'red', cursor: 'pointer' }}
          onClick={handleSupportClick}
        >
          {t('support_center')}
        </p>
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          <AlipayOutlined />{' '}
          <Select
            value={i18n.language}
            onChange={handleLanguageChange}
            style={{ marginLeft: 10, border: 'none', boxShadow: 'none' }}
          >
            <Select.Option value="vi">Tiếng Việt</Select.Option>
            <Select.Option value="cn">Chinese</Select.Option>
            <Select.Option value="en">English</Select.Option>
            <Select.Option value="in">Indonesian</Select.Option>
            <Select.Option value="kr">Korean</Select.Option>
            <Select.Option value="jp">Japanese</Select.Option>
          </Select>
        </div>
      </div>
      {/* {isLoading && <LoadingProgress />} */}
    </div>
  );
}

export default Login;
