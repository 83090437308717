import { LockOutlined } from '@ant-design/icons'
import { Button, Carousel } from 'antd'
import R from 'assets'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Styled from '../styled'
import { useTranslation } from 'react-i18next'

type Props = { data: any; isValid: boolean }

const contentStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  aspectRatio: '1.7',
  borderRadius: 3,
}

const QuestComp: React.FC<Props> = ({ data, isValid }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const CategoryProduct = ({ categoryKey }: { categoryKey: string }) => {
    const { t } = useTranslation();

    switch (categoryKey) {
      case "Vip 1":
        return <span>{t('vip_sliver')}</span>;
      case "Vip 2":
        return <span>{t('vip_gold')}</span>;
      case 'Vip 3':
        return <span>{t('vip_platinum')}</span>;
      case 'Vip 4':
        return <span>{t('vip_diamond')}</span>;
      case 'Vip 5':
        return <span>{t('vip_crown')}</span>;
      default:
        return <span>{categoryKey}</span>;
    }
  };

  const NameVip = ({ nameKey }: { nameKey: string },) => {
    const { t } = useTranslation();

    switch (nameKey) {
      case "Bạc ":
        return <span>{t('vip_sliver_name')}</span>;
      case "Vàng ":
        return <span>{t('vip_gold_name')}</span>;
      case 'Bạch Kim ':
        return <span>{t('vip_platinum_name')}</span>;
      case 'Kim Cương':
        return <span>{t('vip_diamond_name')}</span>;
      case 'Vương Miện':
        return <span>{t('vip_crown_name')}</span>;
      default:
        return <span>{nameKey}</span>;
    }
  };

  const VipBonus = ({ name, bonus }: { name: string, bonus: string }) => {
    const { t } = useTranslation();

    switch (name) {
      case 'Vip 1':
        return <span>200$ - 60 Oder - {bonus}%</span>;
      case 'Vip 2':
        return <span>1000$ - 90 Oder - {bonus}% bonus 200$</span>;
      case 'Vip 3':
        return <span>3000$ - 120 Oder - {bonus}% - bonus 500$</span>;
      case 'Vip 4':
        return <span>5000$ - 150 Oder - {bonus}% - bonus 800$</span>;
      case 'Vip 5':
        return <span>10.000$ - 180 Oder - {bonus}% - bonus 1000$</span>;
      default:
        return <span>{bonus}%</span>;
    }
  };

  return (
    <Styled.WarpQuest xs={24} lg={12}>
      <div className="main-quest">
        {/* <span className="rank-name">{data?.name}</span>
         */}
        <span className="rank-name">
          <NameVip nameKey={data?.name} />
        </span>
        {/* <span className="discount">{data?.commission_percent}%</span> */}
        <span className="discount">
          <VipBonus name={data?.name} bonus={data?.commission_percent} />
        </span>
        <span style={{ marginBottom: 5 }} className="category-product">
          <CategoryProduct categoryKey={data?.name} />
        </span>
        <span className="image-quest">
          <Carousel autoplay>
            {data?.background_urls.map((img: any) => (
              <div>
                <img
                  alt="banner"
                  src={`${process.env.REACT_APP_IMG_URL}${img}`}
                  crossOrigin={'anonymous'}
                  style={contentStyle}
                />
              </div>
            ))}
          </Carousel>
        </span>
        <Styled.ButtonStyled
          onClick={() => {
            if (isValid)
              history.push(`${ADMIN_ROUTER_PATH.ORDER}?key=${data.key}`)
            else history.push(ADMIN_ROUTER_PATH.MEMBER_RANK)
          }}
          icon={!isValid && <LockOutlined />}
        >
          {isValid ? t('active') : t('open_key_level')}
        </Styled.ButtonStyled>
      </div>
    </Styled.WarpQuest>
  )
}
export default QuestComp
