const images = {
  img_background: require('./img_background.jpg').default,
  logo_web: require('./logo_web_1.png'),
  banner1: require('./banner1.png'),
  banner2: require('./banner2.png'),
  banner3: require('./banner3.png'),
  banner4: require('./banner4.png'),
  banner5: require('./banner5.png'),
  img_countdown: require('./img_countdown.gif'),
  bg: require('./bg.jpg'),
  logo: require('./logo.png'),
  avt_placeholder: require('./avt_placeholder.png'),
  notify: require('./notify.jpg'),
  visa_img: require('./visa_img.gif'),
}
export default images
