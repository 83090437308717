import { Col } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { getImageFromServer } from 'utils/funcHelper'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { requestHistory } from './api/ApiHistory'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'

const HistoryPage: React.FC = () => {
  const [listOrder, setlistOrder] = useState<any>([])
  const [orderBlock, setOrderBlock] = useState<any>()
  const { t } = useTranslation()

  const [params, setParams] = useState<any>({
    status: undefined,
    limit: 1000,
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('status')
    if (myParam) {
      setParams({ ...params, status: myParam })
    } else {
      setParams({ ...params, status: undefined })
    }
  }, [window.location.search])

  useEffect(() => {
    getHistoryList()
  }, [params])

  const getHistoryList = async () => {
    let dataOrderBlock: Array<any> = []
    try {
      const res = await requestHistory(params)
      const convertData = res.data?.map((item: any) => ({
        id: item?._id,
        image_url: item.product?.image,
        name_product: item.product?.name,
        price_product: item.meta?.value,
        commission: item.meta?.commission,
        status: item.status,
        is_block_create_order: item?.user?.is_block_create_order,
      }))

      if (params.status == 'Processing') {
        Cookies.set('block_order', JSON.stringify(convertData[0]))
      }
      if (params.status == 'Frozen') {
        let item: any = await Cookies.get('block_order')
        item = JSON.parse(item)
        console.log('Frozen', item)
        if (item?.is_block_create_order == 0) {
          dataOrderBlock = [JSON.parse(item)]
          return
        }
      }
      setlistOrder(dataOrderBlock?.length ? dataOrderBlock : convertData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getColorForTagStatus = (status: string) => {
    switch (status) {
      case 'Processing':
        return { color: 'var(--orange-1)', text: t('pending') }
      case 'Success':
        return { color: 'var(--green-1)', text: t('finish') }
      case 'Frozen':
        return { color: 'var(--blue-1)', text: t('fozen') }
      default:
        return { color: 'var(--orange-1)', text: t('pending') }
    }
  }

  return (
    <Styled.ContainerHistory>
      <Styled.TopTitle>
        <div className="title">{t('history_order')}</div>
        <span>{t('note_order_history')}</span>
      </Styled.TopTitle>
      <Styled.ContainerTabs>
        <Styled.HeaderTabs>
          <Col
            span={6}
            className={`title-tabs ${!params.status ? 'active' : ''}`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.HISTORY,
              })
            }}
          >
            {t('all')}
          </Col>
          <Col
            span={6}
            className={`title-tabs ${
              params.status === 'Processing' ? 'active' : ''
            }`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.HISTORY,
                search: '?status=Processing',
              })
            }}
          >
            {t('pending')}
          </Col>
          <Col
            span={6}
            className={`title-tabs ${
              params.status === 'Frozen' ? 'active' : ''
            }`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.HISTORY,
                search: '?status=Frozen',
              })
            }}
          >
            {t('fozen')}
          </Col>
          <Col
            span={6}
            className={`title-tabs ${
              params.status === 'Success' ? 'active' : ''
            }`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.HISTORY,
                search: '?status=Success',
              })
            }}
          >
            {t('finish')}
          </Col>
        </Styled.HeaderTabs>
      </Styled.ContainerTabs>

      <Styled.ContentTab>
        {listOrder?.map((item: any) => (
          <Styled.OrderBlock
            key={item.id}
            onClick={() => {
              if (item.status === 'Processing')
                history.push({
                  pathname: ADMIN_ROUTER_PATH.DETAIL_ORDER,
                  state: item,
                })
            }}
          >
            <div
              className="tag-status"
              style={{
                backgroundColor: getColorForTagStatus(item.status).color,
              }}
            >
              {getColorForTagStatus(item.status).text}
            </div>
            <div className="name-product">{item.name_product}</div>
            <div className="inner-info">
              <img
                alt="product"
                src={getImageFromServer(item.image_url)}
                crossOrigin="anonymous"
              />
              <div className="price-order">
                <div className="price-block">
                  <div>{t('price')}(đ)</div>
                  <div style={{ color: 'var(--green-1)' }}>
                    ${formatPrice(item.price_product)}
                  </div>
                </div>
                <div className="price-block">
                  <div>{t('commission')}(đ)</div>
                  <div style={{ color: 'var(--orange-1)' }}>
                    ${formatPrice(item.commission)}
                  </div>
                </div>
              </div>
            </div>
          </Styled.OrderBlock>
        ))}
      </Styled.ContentTab>
    </Styled.ContainerHistory>
  )
}

export default HistoryPage
