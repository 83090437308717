import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Select } from 'antd'
// import LoadingProgress from "common/components/LoadingProgress";
import { SESSION_KEY } from 'common/config'
// import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import { useState } from 'react'
import { REG_PHONE } from 'utils/constants'
import history from 'utils/history'
import R from 'assets'
import { requestLogin, requestSignin } from './AuthApi'
import './authStyle.css'
import { useDispatch } from 'react-redux'
import { getUserInfoAction } from './AuthSlice'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import i18n from 'assets/i18n/translation'


const handleLanguageChange = (value: string) => {
  i18n.changeLanguage(value);
  localStorage.setItem('language', value);
};

function Register(props: any) {
  const [form] = Form.useForm()
  const history = useHistory()

  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()

  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const resRegister = await requestSignin({
        identifier: values.identifier,
        name: values.name,
        phone: values.phone,
        password: values.password,
        tfa_password: values.tfa_pass,
        reference_code: values.invite_code,
      })
      Cookie.set(SESSION_KEY.SESSION, resRegister.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      })
      dispatch(getUserInfoAction())
      message.success('Đăng ký thành công')
      setTimeout(() => {
        history.replace('/')
      }, 1000)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <div className="login">
      <img alt="" src={R.images.bg} className="login-bg" />
      <div className="container-login">
        <img
          className="login-logo"
          width={150}
          src={R.images.logo_web}
          alt=""
        />
        <Form
          form={form}
          layout={'vertical'}
          name="normal_login"
          className="login-form"
          initialValues={{
            identifier: null,
            phone: null,
            username: null,
            password: null,
            tfa_pass: null,
            invite_code: null,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label={t('full_name')}
            rules={[
              { required: true, message: t('not_empty') },
              // {
              //   pattern: REG_PHONE,
              //   message: 'Số điện thoại không hợp lệ',
              // },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('user_login')}
            />
          </Form.Item>{' '}
          <Form.Item
            name="identifier"
            label={t('user_login')}
            rules={[
              { required: true, message: t('not_empty') },
              // {
              //   pattern: REG_PHONE,
              //   message: 'Số điện thoại không hợp lệ',
              // },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('user_login')}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t('phone')}
            rules={[{ required: true, message: t('not_empty') }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('phone')}
            />
          </Form.Item>
          <Form.Item
            label={t('password')}
            name="password"
            rules={[
              { required: true, message: t('not_empty') },
              { min: 6, message: t('validate_character') },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('password')}
            />
          </Form.Item>
          <Form.Item
            label={t('capital_password')}
            name="tfa_pass"
            rules={[
              { required: true, message: t('not_empty') },
              { min: 6, message: t('validate_character') },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder={t('capital_password')}
            />
          </Form.Item>
          <Form.Item
            label={t('code_invite')}
            name="invite_code"
            rules={[
              { required: true, message: t('not_empty') },
              { len: 6, message: t('referral_code_6_chars') },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={t('code_invite')}
            />
          </Form.Item>
          <Form.Item
            style={{
              textAlign: 'center',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className=" login-form-button"
              style={{
                width: '100%',
                height: 40,
                fontWeight: 700,
                marginTop: 20,
              }}
            >
              {t("register")}
            </Button>
          </Form.Item>
        </Form>
        <p className="login-text__btm">
          {t("already_account")}?{' '}
          <a onClick={() => history.push('/login')}> {t("login")}</a>
        </p>
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          <Select
            value={i18n.language}
            onChange={handleLanguageChange}
            style={{ marginLeft: 10, border: 'none', boxShadow: 'none' }}
          >
            <Select.Option value="vi">Tiếng Việt</Select.Option>
            <Select.Option value="cn">Chinese</Select.Option>
            <Select.Option value="en">English</Select.Option>
            <Select.Option value="in">Indonesian</Select.Option>
            <Select.Option value="kr">Korean</Select.Option>
            <Select.Option value="jp">Japanese</Select.Option>
          </Select>
        </div>
      </div>
      {/* {isLoading && <LoadingProgress />} */}
    </div>
  )
}

export default Register
